import Styles from "./Dashboard.module.css";
import React, { useCallback, useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
// import { zodResolver } from "@hookform/resolvers/zod";
// import * as z from "zod";
import CustomPagination from "../../../components/Common/Pagination/ui/Pagination.js";
import { Input } from "../../../components/Common/index.js";
import { CircularProgress } from "@mui/joy";
import {
  ChartArc,
  Navigation,
  Table,
} from "../../../components/Layout/index.js";

import {
  useFiltersData,
  useChartData,
  useDashboardColumns,
  useDashboardLogic,
} from "../Logic/index.js";
import Modal from "../../../components/Common/Modal/Modal.js";
import Button from "../../../components/Common/Button/Button.js";
import useCredentialLogic from "../Logic/useCredentialLogic.js";
import CustomSlider from "../../../components/Common/CustomSlider/CustomSlider.js";
import FiltersIsland from "../../../components/Layout/FiltersIsland/FiltersIsland.js";
import Seo from "../../../utils/SeoHelmet/Seo.js";
import { publicIpv4 } from "public-ip";
import axios from "axios";
import companyImg from "../../../images/companyPage.png";
import { useLocation } from "react-router-dom";

const Dashboard = () => {
  const [search, setSearch] = useState("");
  const { pathname } = useLocation();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(null);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [emailSuccessMessage, setEmailSuccessMessage] = useState(null);
  const [emailLoading, setEmailLoading] = useState(false);
  const [conditions, setConditions] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState([0, 40]);
  const [filters, setFilters] = useState({
    exchange_short_name: null,
    rule_of_forty: ["btwn"],
    industry: null,
    sector: null,
    marketcap: null,
  });
  const [cookieConsent, setCookieConsent] = useState(false);
  const [stockSymbol, setStockSymbol] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [modalCheckboxValue, setModalCheckboxValue] = useState([]);
  const [nameEmail, setNameEmail] = useState({
    name: null,
    email: null,
    emailBody: null,
    columns: [],
  });
  const [symbolModal, setSymbolModal] = useState(false);
  const [symbol, setSymbol] = useState();

  const [popUpInitial, setPopUpInitial] = useState(false);

  useEffect(() => {
    if (pathname === "/") {
      const timeOut = setTimeout(() => setStockSymbol(search), 500);
      return () => clearTimeout(timeOut);
    }
  }, [search, setStockSymbol, pathname]);

  //Hooks
  const {
    industries,
    // sectors,
    exchange,
    filterIndustry,
    filterSector,
    filterExchange,
  } = useFiltersData(filters, setConditions, value);
  const {
    columns,
    displayColumns,
    sortField,
    sortType,
    handleCheckboxClick,
    handleResetColumn,
  } = useDashboardColumns(setSymbolModal, setSymbol);
  const { data, csvDataFiltered, clearFilters } = useDashboardLogic(
    conditions,
    page,
    rowsPerPage,
    stockSymbol,
    sortField,
    sortType,
    displayColumns,
    setFilters,
    handleResetColumn
  );

  const chartData = useChartData(conditions, stockSymbol);

  const setStockSymbolCallback = useCallback((symbol) => {
    setStockSymbol(symbol);
  }, []);

  const {
    updateCredentials,
    onSubmit,
    error,
    loading,
    success,
    setSuccess,
    setError,
  } = useCredentialLogic({
    setNameEmail,
    nameEmail,
    setOpenModal,
  });

  const handleMouseUp = () => {
    if (
      value[0] !== -100 &&
      value[0] !== 100 &&
      value[1] !== -100 &&
      value[1] !== 100
    ) {
      setFilters((prev) => {
        return {
          ...prev,
          rule_of_forty: ["btwn"],
        };
      });
    }

    if (value[0] === -100 && value[1] !== 100) {
      setFilters((prev) => {
        return {
          ...prev,
          rule_of_forty: ["<"],
        };
      });
    }
    if (value[0] !== -100 && value[1] === 100) {
      setFilters((prev) => {
        return {
          ...prev,
          rule_of_forty: [">"],
        };
      });
    }
    if (value[0] === -100 && value[1] === 100) {
      setFilters((prev) => {
        return {
          ...prev,
          rule_of_forty: null,
        };
      });
    }
  };

  const formatString = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    const formatCheckboxValue = () => {
      const formattedMap = Object.keys(displayColumns)
        .sort()
        .reduce((acc, item) => {
          if (item !== "stock_symbol") {
            acc[item] = {
              key: item,
              text: formatString(item),
            };
          }
          return acc;
        }, {});

      setModalCheckboxValue(formattedMap);
    };
    formatCheckboxValue();
  }, [displayColumns]);

  const getCookierByName = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const [cookieName, cookieValue] = cookies[i].split("=");
      if (cookieName.trim() === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  };

  useEffect(() => {
    const checkCookieConsent = () => {
      const cookiesConsent = getCookierByName("cookie_consent_level");
      const cookieTracking = JSON.parse(cookiesConsent)?.tracking;
      setCookieConsent(cookieTracking);
    };

    checkCookieConsent();

    const interval = setTimeout(() => {
      checkCookieConsent();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (cookieConsent) {
      const checkIpEmail = async () => {
        const isIpChecked = sessionStorage.getItem("ipcheck");

        try {
          const body = {
            ip: await publicIpv4(),
          };
          const [checkIp, checkEmail] = await Promise.all([
            !isIpChecked
              ? axios.post("/api/auth/ipchecker", body)
              : { data: { visit: 2 } },
            axios.post("/api/auth/emailchecker", body),
          ]);
          const visit = checkIp.data?.visit;
          const email = checkEmail.data?.email;

          if (visit <= 1 && !email) {
            sessionStorage.setItem("ipcheck", "true");
            setTimeout(() => {
              setPopUpInitial(true);
            }, 30000);
          } else if (visit > 1 && !email) {
            setPopUpInitial(true);
          } else {
            sessionStorage.setItem("user_email", "true");
            setPopUpInitial(false);
          }
        } catch (error) {
          console.log(error);
        }
      };
      checkIpEmail();

      const intervalId = setInterval(() => {
        if (
          sessionStorage.getItem("ipcheck") &&
          sessionStorage.getItem("user_email")
        ) {
          console.log("Stopping interval as both values are present.");
          clearInterval(intervalId);
        } else {
          checkIpEmail();
        }
      }, 180000);

      return () => clearInterval(intervalId);
    }
  }, [cookieConsent]);

  // useEffect(() => {
  //   const checkEmail = async () => {
  //     try {
  //       const body = {
  //         ip: await publicIpv4(),
  //       };
  //       const emailCheck = await axios.post("/api/auth/emailchecker", body);
  //       const exist = emailCheck.data?.exists;
  //       console.log(exist);
  //       if (!exist) {
  //         setPopUpInitial(true);
  //       } else {
  //         sessionStorage.setItem("user_email", "true");
  //       }
  //       // console.log(emailCheck.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   const isIpChecked = sessionStorage.getItem("ipcheck");
  //   const isEmail = sessionStorage.getItem("user_email");
  //   if (cookieConsent) {
  //     if (!isIpChecked) {
  //       const checkIp = async () => {
  //         try {
  //           const body = {
  //             ip: await publicIpv4(),
  //           };

  //           const check = await axios.post("/api/auth/ipchecker", body);
  //           if (check.data?.visit <= 1) {
  //             sessionStorage.setItem("ipcheck", "true");
  //             setTimeout(() => setPopUpInitial(true), 30000);
  //           } else {
  //             sessionStorage.setItem("ipcheck", "true");
  //           }
  //         } catch (error) {
  //           console.log(error?.response?.data);
  //         }
  //       };
  //       checkIp();
  //     } else if (!isEmail) {
  //       checkEmail();
  //       const intervalId = setInterval(() => {
  //         checkEmail();
  //       }, 60000);

  //       return () => clearInterval(intervalId);
  //     }
  //   }
  // }, [cookieConsent]);

  const handleOpenPage = () => {
    if (symbol) {
      const url = `https://app.levelfields.ai/home/?symbol=${symbol}`;
      window.open(url, "_blank");
    }
  };

  const handleCheckEmail = (email) => {
    const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailReg.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  const handleEmailList = async (e) => {
    e.preventDefault();
    setEmailLoading(true);
    setEmailError(null);
    try {
      if (!name) return setNameError("Please provide your name");
      const emailCheck = handleCheckEmail(email);
      if (emailCheck) {
        const body = {
          name: name,
          email: email,
          ip: await publicIpv4(),
        };
        const sendEmail = await axios.post("/api/email/emaillist", body);
        if (sendEmail.status === 200) {
          if (sendEmail.data === "Email already in db") {
            setEmailSuccessMessage("Thanks, You're already subscribed");
            setTimeout(() => {
              setPopUpInitial(false);
              setEmailSuccessMessage(null);
            }, 3000);
          } else {
            setEmailSuccessMessage("Thanks for subscribing");
            setTimeout(() => {
              setPopUpInitial(false);
              setEmailSuccessMessage(null);
            }, 3000);
          }
        }
      } else {
        setEmailError("Email invalid, change format");
      }
    } catch (error) {
      console.log(error);
      setEmailError("Something went wrong");
    } finally {
      setEmailLoading(false);
    }
  };

  return (
    <div className={Styles.App}>
      <Seo
        description={
          "Discover the ultimate Rule of 40 calculator and analysis tool for SaaS businesses. Evaluate growth vs. profitability effortlessly and optimize your strategy for sustainable success. Perfect for investors, traders and analysts seeking actionable insights into high-growth companies."
        }
      />
      <>
        {data.result && industries && chartData && exchange ? (
          <div className={Styles.AppContent}>
            <Navigation
              setStockSymbol={setStockSymbolCallback}
              data={csvDataFiltered}
              setOpenModal={setOpenModal}
            />
            <div className={Styles.MainContent}>
              <div className={Styles.ContentWrapper}>
                <div className={Styles.HeroContent}>
                  <div className={Styles.HeroText}>
                    <h3>Rule of 40</h3>
                    <p>
                      The Rule of 40 is a principle that states a software
                      company's combined <strong>Revenue Growth Rate</strong>{" "}
                      and <strong>EBITDA Margin </strong>
                      should equal or exceed 40% to be considered exceptional.
                    </p>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "2px",
                          margin: "15px 0px",
                        }}
                      >
                        <p
                          style={{
                            margin: "0px",
                            textAlign: "center",
                            fontWeight: "400",
                            fontSize: "16px",
                          }}
                        >
                          Set Calculation Range for the Rule of 40 Score
                        </p>
                        <p
                          style={{
                            margin: "0px",
                            textAlign: "center",
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "var(--color-gray-60)",
                          }}
                        >
                          A Score of 40 and over is best.
                        </p>
                      </div>
                      <CustomSlider
                        value={value}
                        setValue={setValue}
                        handleMouseUp={handleMouseUp}
                      />
                    </div>
                  </div>
                  <ChartArc chartData={chartData} data={data} />
                </div>

                <div className={Styles.TableWrapper}>
                  <div className={Styles.FiltersWrapper}>
                    <div
                      style={{
                        maxWidth: "200px",
                        minWidth: "200px",
                      }}
                    >
                      <Input
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={"Search Symbol..."}
                      />
                    </div>
                  </div>
                  <FiltersIsland
                    displayColumns={displayColumns}
                    handleCheckboxClick={handleCheckboxClick}
                    clearFilters={clearFilters}
                    filters={filters}
                    setFilters={setFilters}
                    filterIndustry={filterIndustry}
                    filterSector={filterSector}
                    filterExchange={filterExchange}
                  />
                  <div className={Styles.TableContent}>
                    <Table data={data.result} columns={columns} />

                    <div className={Styles.PaginationWrapper}>
                      <CustomPagination
                        count={data?.count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(newPage) => setPage(newPage)}
                        onRowsPerPageChange={(newRowsPerPage) => {
                          setRowsPerPage(newRowsPerPage);
                          setPage(0);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              open={openModal}
              setOpen={() => {
                setOpenModal(false);
                setSuccess();
                setError();
                setNameEmail({
                  name: null,
                  email: null,
                  emailBody: null,
                  columns: [],
                });
              }}
              title={"Request the data with this form."}
              closeBtn={true}
              mobile={true}
            >
              <Input
                label
                textLabel={"Full Name"}
                required
                placeholder={"Name"}
                name="name"
                error={error === "Name is missing." ? true : false}
                onChange={(e) => updateCredentials(e)}
              />
              <Input
                label
                type={"email"}
                textLabel={"Email"}
                required
                placeholder={"example@email.com"}
                name="email"
                error={
                  error === "Email is missing." || error === "Email not valid."
                    ? true
                    : false
                }
                onChange={(e) => updateCredentials(e)}
              />
              <div className={Styles.ColumnsSelectionOutsideWrapper}>
                <h5>
                  Request columns <span className={Styles.Required}>*</span>
                </h5>
                <p>File already includes Stock Symbols</p>
                <div className={Styles.ColumnsSelectionWrapper}>
                  <div className={Styles.ColumnsSelection}>
                    {Object.values(modalCheckboxValue)
                      .slice(
                        0,
                        Math.ceil(Object.keys(modalCheckboxValue).length / 3)
                      )
                      .map((item, index) => (
                        <Input
                          key={item.key}
                          id={`checkbox-value-${item.key}`}
                          type="checkbox"
                          name="columns"
                          value={item.key}
                          textLabel={
                            item.text === "Rule Of Forty"
                              ? "Score"
                              : item.text === "Eps"
                              ? "EPS"
                              : item.text === "Revenue Growth Ttm"
                              ? "Revenue Growth TTM"
                              : item.text === "Ebitda Margins"
                              ? "EBITDA Margin"
                              : item.text === "Net Profit Marging"
                              ? "Net Profit Margin"
                              : item.text === "Revenue Growth"
                              ? "Revenue Growth (Qtr)"
                              : item.text === "Exchange Short Name"
                              ? "Exchange"
                              : item.text === "Price Change Percentage"
                              ? "1D Price Change"
                              : item.text
                          }
                          className={Styles.Checkbox}
                          onChange={(e) => updateCredentials(e)}
                          index={index}
                        />
                      ))}
                  </div>
                  <div className={Styles.ColumnsSelection}>
                    {Object.values(modalCheckboxValue)
                      .slice(
                        Math.ceil(Object.keys(modalCheckboxValue).length / 3),
                        Math.ceil(Object.keys(modalCheckboxValue).length / 3) *
                          2
                      )
                      .map((item, index) => (
                        <Input
                          key={item.key}
                          id={`checkbox-value-${item.key}`}
                          type="checkbox"
                          name="columns"
                          value={item.key}
                          textLabel={
                            item.text === "Rule Of Forty"
                              ? "Score"
                              : item.text === "Eps"
                              ? "EPS"
                              : item.text === "Revenue Growth Ttm"
                              ? "Revenue Growth TTM"
                              : item.text === "Ebitda Margins"
                              ? "EBITDA Margin"
                              : item.text === "Net Profit Marging"
                              ? "Net Profit Margin"
                              : item.text === "Revenue Growth"
                              ? "Revenue Growth (Qtr)"
                              : item.text === "Exchange Short Name"
                              ? "Exchange"
                              : item.text === "Price Change Percentage"
                              ? "1D Price Change"
                              : item.text
                          }
                          className={Styles.Checkbox}
                          onChange={(e) => updateCredentials(e)}
                          index={index}
                        />
                      ))}
                  </div>
                  <div className={Styles.ColumnsSelection}>
                    {Object.values(modalCheckboxValue)
                      .slice(
                        Math.ceil(Object.keys(modalCheckboxValue).length / 3) *
                          2,
                        Object.keys(modalCheckboxValue).length
                      )
                      .map((item, index) => (
                        <Input
                          key={item.key}
                          id={`checkbox-value-${item.key}`}
                          type="checkbox"
                          name="columns"
                          value={item.key}
                          textLabel={
                            item.text === "Rule Of Forty"
                              ? "Score"
                              : item.text === "Eps"
                              ? "EPS"
                              : item.text === "Revenue Growth Ttm"
                              ? "Revenue Growth TTM"
                              : item.text === "Ebitda Margins"
                              ? "EBITDA Margin"
                              : item.text === "Net Profit Marging"
                              ? "Net Profit Margin"
                              : item.text === "Revenue Growth"
                              ? "Revenue Growth (Qtr)"
                              : item.text === "Exchange Short Name"
                              ? "Exchange"
                              : item.text === "Price Change Percentage"
                              ? "1D Price Change"
                              : item.text
                          }
                          className={Styles.Checkbox}
                          onChange={(e) => updateCredentials(e)}
                          index={index}
                        />
                      ))}
                  </div>
                </div>
              </div>

              {error ? <div className={Styles.ErrorText}>{error}</div> : null}
              {loading ? (
                <CircularProgress />
              ) : success && openModal ? (
                <div className={Styles.SuccessMessage}>
                  <p>{success}</p>
                </div>
              ) : (
                <Button style={{ width: "100%" }} onClick={onSubmit}>
                  Submit
                </Button>
              )}
            </Modal>
            {/* <Modal open={limitModal} title={"Ops..."}>
              <div className={Styles.ModalBody}>
                <p>
                  It looks like you really like our website, you hit the daily
                  limit. But if you want to continue please let us know which
                  data you need.
                </p>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => {
                    setLimitModal(false);
                    setOpenModal(true);
                  }}
                >
                  Request Data
                </Button>
              </div>
            </Modal> */}
            <Modal
              open={popUpInitial}
              title={"Welcome"}
              closeBtn={true}
              setOpen={() => setPopUpInitial(false)}
            >
              <div className={Styles.ModalBody}>
                <p>
                  Please enter your email to continue using the site for free.
                  We'll only use the email to notify you of new features as they
                  roll out.
                </p>

                <Input
                  label={true}
                  textLabel={"Name"}
                  placeholder={"First name"}
                  required={true}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(null);
                  }}
                  error={nameError}
                />
                {nameError ? (
                  <p className={Styles.ErrorText}>{nameError}</p>
                ) : null}
                <Input
                  label={true}
                  textLabel={"Email"}
                  placeholder={"example@email.com"}
                  required={true}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(null);
                  }}
                  error={emailError}
                />
                {emailError ? (
                  <p className={Styles.ErrorText}>{emailError}</p>
                ) : null}
                {!emailLoading ? (
                  <Button
                    style={{ width: "100%" }}
                    onClick={handleEmailList}
                    type={"submit"}
                  >
                    Send
                  </Button>
                ) : (
                  <CircularProgress />
                )}

                {emailSuccessMessage ? (
                  <div className={Styles.SuccessMessage}>
                    <p>{emailSuccessMessage}</p>
                  </div>
                ) : null}
              </div>
            </Modal>
            <Modal
              open={symbolModal}
              closeBtn={true}
              setOpen={() => setSymbolModal(false)}
              title={"Access company details"}
            >
              <img
                src={companyImg}
                alt="company"
                className={Styles.CompanyImg}
              />
              <p
                style={{
                  margin: "0px",
                }}
              >
                View the company’s profile, financials, and recent material
                events at LevelFields by clicking the button below.
              </p>
              <Button
                onClick={handleOpenPage}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                }}
              >
                Go to LevelFields
              </Button>
            </Modal>
          </div>
        ) : (
          <div className={Styles.Loader}>
            <CircularProgress />
          </div>
        )}
      </>
    </div>
  );
};

export default Dashboard;
