import React, { useEffect, useRef, useState } from "react";
import Styles from "./FiltersIsland.module.css";
import { Filters, Input } from "../../Common/index.js";
import {
  columnItems,
  marketcapObj,
} from "../../../Pages/Dashboard/Logic/obj.js";
const FiltersIsland = ({
  clearFilters,
  handleCheckboxClick,
  displayColumns,
  filters,
  setFilters,
  filterIndustry,
  filterSector,
  filterExchange,
}) => {
  const [columnModal, setColumnModal] = useState({
    columns: false,
    industry: false,
    sector: false,
    exchange: false,
    marketcap: false,
  });

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setColumnModal({
        columns: false,
        industry: false,
        sector: false,
        exchange: false,
        marketcap: false,
      });
    }
  };

  const handleCheckboxMultiple = (key, value) => {
    setFilters((prev) => {
      const currentFilter = prev[key] || [];
      if (currentFilter.includes(value)) {
        return {
          ...prev,
          [key]: currentFilter.filter((item) => item !== value),
        };
      } else {
        return {
          ...prev,
          [key]: [...currentFilter, value],
        };
      }
    });
  };

  const modalRef = useRef(null);

  useEffect(() => {
    if (
      columnModal.columns ||
      columnModal.industry ||
      columnModal.sector ||
      columnModal.exchange ||
      columnModal.marketcap
    ) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [columnModal]);

  columnItems.sort((a, b) => (a.label || "").localeCompare(b.label || ""));

  return (
    <div className={Styles.FiltersWrapper}>
      {/* {width < 1000 ? (
        <div>
          <div
            className={Styles.ColumnModalWrapper}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            {mobileModal ? (
              <>
                {columnModal.columns ? (
                  <div className={Styles.ColumnModal} ref={modalRef}>
                    {columnItems
                      .sort((a, b) =>
                        (a.label || "").localeCompare(b.label || "")
                      )
                      .map(({ key, label }, index) => {
                        return (
                          <Input
                            name={key}
                            type="checkbox"
                            className={Styles.Checkbox}
                            value={label}
                            checked={!!displayColumns[key]}
                            onChange={() => handleCheckboxClick(key)}
                            textLabel={label}
                            index={index}
                          />
                        );
                      })}
                  </div>
                ) : null}
                {columnModal.industry ? (
                  <div
                    className={Styles.ColumnModal}
                    style={{
                      left: "-110px",
                      width: "350px",
                    }}
                    ref={modalRef}
                  >
                    {filterIndustry
                      .map(({ name }, index) => {
                        return (
                          <Input
                            name={name}
                            type="checkbox"
                            className={Styles.Checkbox}
                            value={name}
                            checked={
                              filters.industry &&
                              filters.industry.includes(name)
                            }
                            onChange={() =>
                              handleCheckboxMultiple("industry", name)
                            }
                            textLabel={name}
                            index={index}
                          />
                        );
                      })
                      .slice(1)}
                  </div>
                ) : null}

                {columnModal.sector ? (
                  <div className={Styles.ColumnModal} ref={modalRef}>
                    {filterSector
                      .map(({ name }, index) => {
                        return (
                          <Input
                            name={name}
                            type="checkbox"
                            className={Styles.Checkbox}
                            value={name}
                            checked={
                              filters.sector && filters.sector.includes(name)
                            }
                            onChange={() =>
                              handleCheckboxMultiple("sector", name)
                            }
                            textLabel={name}
                            index={index}
                          />
                        );
                      })
                      .slice(1)}
                  </div>
                ) : null}
                {columnModal.exchange ? (
                  <div className={Styles.ColumnModal} ref={modalRef}>
                    {filterExchange
                      .map(({ name }, index) => {
                        return (
                          <Input
                            name={name}
                            type="checkbox"
                            className={Styles.Checkbox}
                            value={name}
                            checked={
                              filters.exchange_short_name &&
                              filters.exchange_short_name.includes(name)
                            }
                            onChange={() =>
                              handleCheckboxMultiple(
                                "exchange_short_name",
                                name
                              )
                            }
                            textLabel={name}
                            index={index}
                          />
                        );
                      })
                      .slice(1)}
                  </div>
                ) : null}

                <button
                  className={Styles.ColumnButton}
                  onClick={() => clearFilters()}
                >
                  Clear
                </button>
                <button
                  className={Styles.ColumnButton}
                  style={{ textAlign: "center" }}
                  onClick={() =>
                    setColumnModal((prev) => {
                      return {
                        ...prev,
                        columns: (prev) => !prev,
                      };
                    })
                  }
                >
                  Columns
                </button>
                <button
                  className={Styles.ColumnButton}
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    setColumnModal((prev) => {
                      return {
                        ...prev,
                        industry: !prev.industry,
                      };
                    });
                  }}
                >
                  Industries
                </button>
                <button
                  className={Styles.ColumnButton}
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    setColumnModal((prev) => {
                      return {
                        ...prev,
                        sector: !prev.sector,
                      };
                    });
                  }}
                >
                  Sector
                </button>

                <Filters
                  style={{ width: "100px", textAlign: "center" }}
                  type={"select"}
                  setFilters={setFilters}
                  filters={filters}
                  obj={ruleFortyObj}
                  options={ruleFortyObj.options ? ruleFortyObj.options : []}
                />
                <Filters
                  style={{ width: "100px", textAlign: "center" }}
                  type={"select"}
                  setFilters={setFilters}
                  filters={filters}
                  obj={marketcapObj}
                  options={marketcapObj.options ? marketcapObj.options : []}
                />

                <button
                  className={Styles.ColumnButton}
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    setColumnModal((prev) => {
                      return {
                        ...prev,
                        exchange: !prev.exchange,
                      };
                    });
                  }}
                >
                  Exchange
                </button>
              </>
            ) : null}
            <button
              className={Styles.ColumnButton}
              style={{ textAlign: "center" }}
              onClick={() => setMobileModal((prev) => !prev)}
            >
              {mobileModal ? "Close" : "Open"}
            </button>
          </div>
        </div>
      ) : ( */}

      <div className={Styles.ColumnModalWrapper}>
        <button
          className={Styles.ColumnButton}
          onClick={() =>
            setColumnModal((prev) => {
              return {
                ...prev,
                columns: !prev.columns,
              };
            })
          }
        >
          Columns
        </button>
        {columnModal.columns ? (
          <div className={Styles.ColumnModal} ref={modalRef}>
            {columnItems.map(({ key, label }, index) => {
              return (
                <Input
                  name={key}
                  type="checkbox"
                  className={Styles.Checkbox}
                  value={label}
                  checked={!!displayColumns[key]}
                  onChange={() => handleCheckboxClick(key)}
                  textLabel={label}
                  index={index}
                />
              );
            })}
          </div>
        ) : null}
      </div>
      <div className={Styles.ColumnModalWrapper}>
        <button
          className={Styles.ColumnButton}
          onClick={() =>
            setColumnModal((prev) => {
              return {
                ...prev,
                industry: !prev.industry,
              };
            })
          }
        >
          Industries
        </button>

        {columnModal.industry ? (
          <div className={Styles.ColumnModal} ref={modalRef}>
            {filterIndustry
              .map(({ name }, index) => {
                return (
                  <Input
                    name={name}
                    type="checkbox"
                    className={Styles.Checkbox}
                    value={name}
                    checked={
                      filters.industry && filters.industry.includes(name)
                    }
                    onChange={() => handleCheckboxMultiple("industry", name)}
                    textLabel={name}
                    index={index}
                  />
                );
              })
              .slice(1)}
          </div>
        ) : null}
      </div>

      {/* <div className={Styles.ColumnModalWrapper}>
        <button
          className={Styles.ColumnButton}
          onClick={() =>
            setColumnModal((prev) => {
              return {
                ...prev,
                sector: !prev.sector,
              };
            })
          }
        >
          Sector
        </button>

        {columnModal.sector ? (
          <div className={Styles.ColumnModal} ref={modalRef}>
            {filterSector
              .map(({ name }, index) => {
                return (
                  <Input
                    name={name}
                    type="checkbox"
                    className={Styles.Checkbox}
                    value={name}
                    checked={filters.sector && filters.sector.includes(name)}
                    onChange={() => handleCheckboxMultiple("sector", name)}
                    textLabel={name}
                    index={index}
                  />
                );
              })
              .slice(1)}
          </div>
        ) : null}
      </div> */}

      {/* <Filters
        style={{ width: "100px" }}
        type={"select"}
        setFilters={setFilters}
        filters={filters?.rule_of_forty}
        obj={ruleFortyObj}
        options={ruleFortyObj.options ? ruleFortyObj.options : []}
      /> */}
      <Filters
        style={{ width: "100px", textAlign: "center" }}
        type={"select"}
        setFilters={setFilters}
        filters={filters?.marketcap}
        obj={marketcapObj}
        options={marketcapObj.options ? marketcapObj.options : []}
      />

      <div className={Styles.ColumnModalWrapper}>
        <button
          className={Styles.ColumnButton}
          onClick={() =>
            setColumnModal((prev) => {
              return {
                ...prev,
                exchange: !prev.exchange,
              };
            })
          }
        >
          Exchange
        </button>

        {columnModal.exchange ? (
          <div className={Styles.ColumnModal} ref={modalRef}>
            {filterExchange
              .map(({ name }, index) => {
                return (
                  <Input
                    name={name}
                    type="checkbox"
                    className={Styles.Checkbox}
                    value={name}
                    checked={
                      filters.exchange_short_name &&
                      filters.exchange_short_name.includes(name)
                    }
                    onChange={() =>
                      handleCheckboxMultiple("exchange_short_name", name)
                    }
                    textLabel={name}
                    index={index}
                  />
                );
              })
              .slice(1)}
          </div>
        ) : null}
      </div>
      <button className={Styles.ColumnButton} onClick={() => clearFilters()}>
        Clear
      </button>

      {/* )} */}
    </div>
  );
};

export default FiltersIsland;
